<template>
  <div class="system-layout">
    <el-container>
      <el-aside :width="isCollapse ? '63px' : '200px'">
        <div v-if="isCollapse" class="system-logo">
          邮
        </div>
        <div v-else class="system-logo">
          厅堂邮约活动管理系统
        </div>
        <menu-bar :collapse="isCollapse" :active="menuActive"></menu-bar>
      </el-aside>
      <el-container>
        <el-header>
          <div class="header-left">
            <span v-if="isCollapse" class="el-icon-s-unfold" @click="isCollapse = false"></span>
            <span v-else class="el-icon-s-fold" @click="isCollapse = true"></span>
          </div>
          <div class="header-right">
            <el-dropdown @command="onDropCommand">
              <div class="header-right-item" style="color: #101010;">
                <img src="@/assets/tx.png" style="width: 24px; height: 24px; margin-right: 8px;">
                {{ userInfo.username }}
              </div>
              <el-dropdown-menu slot="dropdown">
                <!-- <el-dropdown-item :command="{name: 'userCenter'}">个人中心</el-dropdown-item> -->
                <el-dropdown-item :command="{name: 'updatePwd'}">修改密码</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <div class="header-right-item" @click="onExitSystem">
              <span v-if="exitLoading" class="el-icon-loading"></span>
              退出
              <img src="@/assets/exit.png" style="width:16px; height: 16px; margin-left: 8px;">
            </div>
          </div>
        </el-header>
        <el-main>
          <router-view></router-view>
        </el-main>
        <!-- <el-footer>
          Copyright © 2023 稻科盛朴 提供技术支持 Rights Reserved. 备案号：xxxxxxx
        </el-footer> -->
      </el-container>
    </el-container>
  </div>
</template>

<script>
import MenuBar from '@/components/MenuBar.vue'
export default {
  name: 'HuijiliWebIndex',
  components: {
    MenuBar
  },
  data() {
    return {
      isCollapse: false,
      menuActive: '',
      exitLoading: false,
      userInfo: {
        username: ''
      }
    };
  },

  mounted() {
    const userInfo = localStorage.getItem('userInfo')
    if (userInfo) {
      this.userInfo = JSON.parse(userInfo)
    } else {
      this.$http({
        method: 'post',
        url: 'user_exit'
      })
      this.$router.push({
        path: '/login'
      })
    }
  },

  methods: {
    onDropCommand({name}) {
      this.menuActive = name
      this.$router.push({
        path: `/${name}`
      })
    },
    onExitSystem() {
      this.$confirm('确定要退出登录吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
      }).then(() => {
        this.$http({
          method: 'post',
          url: 'user_exit'
        })
        .then(res => {
          if (res.errno == 0) {
            this.exitLoading = false
            localStorage.removeItem('userInfo')
            this.$router.push({
              path: '/login'
            })
          } else {
            this.$message.error('服务端异常，请稍后再试')
          }
        })
        .catch(err => {
          this.$message.error('服务端异常，请稍后再试')
        })
        
      }).catch(() => {});
    }
  },
};
</script>

<style lang="scss" scoped>
.system-layout {
  width: 100%;
  height: 100%;
  overflow: hidden;

  .el-container {
    height: 100%;
  }
}
.el-header {
  user-select: none;
  background-color: #FFFFFF;
  height: 60px;
  box-shadow: 0px 1px 2px 2px rgba($color: #000000, $alpha: 0.05);
  z-index: 1;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #101010;

  .header-left {
    font-size: 20px;
    
    span {
      margin: 0px 4px;
      cursor: pointer;
    }
  }

  .header-right {
    display: flex;
    align-items: center;
    color: #101010;
    font-size: 14px;

    .header-right-item {
      margin: 0px 12px;
      cursor: pointer;
      display: flex;
      align-items: center;
    }
  }
}

.el-footer {
  background-color: #EFF1F4;
  color: #333;
  text-align: center;
  line-height: 60px;
  font-size: 12px;
}
  
.el-aside {
  background-color: #20222A;
  color: #BDBDC0;
  z-index: 2;
  user-select: none;

  .system-logo {
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    font-weight: 600;
    font-size: 17px;
  }
}
  
.el-main {
  background-color: #EFF1F4;
  color: #333;
}
  
body > .el-container {
  margin-bottom: 40px;
}
  
.el-container:nth-child(5) .el-aside,
.el-container:nth-child(6) .el-aside {
  line-height: 260px;
}
  
.el-container:nth-child(7) .el-aside {
  line-height: 320px;
}
</style>