<template>
  <div>
    <el-menu
      :default-active="defaultActive"
      background-color="#20222A"
      text-color="#BDBDC0"
      style="border: none;"
      router
      :collapse="collapse"
      :collapse-transition="false"
      @open="handleOpen"
      @close="handleClose">
      <el-menu-item index="/home">
        <i class="iconfont icon-zonghekongzhitai"></i>
        <span slot="title">工作台</span>
      </el-menu-item>
      <el-menu-item v-if="['1', '2'].includes(userPrower)" index="/store">
        <i class="iconfont icon-mendianguanli"></i>
        <span slot="title">网点管理</span>
      </el-menu-item>
      <el-menu-item v-if="['1', '2'].includes(userPrower)" index="/employee">
        <i class="iconfont icon-yuangongguanli"></i>
        <span slot="title">员工管理</span>
      </el-menu-item>
      <el-menu-item v-if="['1', '2'].includes(userPrower)" index="/preactivity">
        <i class="iconfont icon-shujushangbao"></i>
        <span slot="title">活动计划</span>
      </el-menu-item>
      <el-menu-item v-else index="/preactivity/examine">
        <i class="iconfont icon-shujushangbao"></i>
        <span slot="title">活动计划</span>
      </el-menu-item>
      <el-menu-item v-if="['1', '2'].includes(userPrower)" index="/activity">
        <i class="iconfont icon-liwuhuodong"></i>
        <span slot="title">活动管理</span>
      </el-menu-item>
      <el-menu-item v-else index="/activity/examine">
        <i class="iconfont icon-liwuhuodong"></i>
        <span slot="title">活动管理</span>
      </el-menu-item>
      <el-menu-item v-if="['1', '2'].includes(userPrower)" index="/report">
        <i class="iconfont icon-tongji"></i>
        <span slot="title">统计报表</span>
      </el-menu-item>
      <el-menu-item v-else index="/report/examine">
        <i class="iconfont icon-tongji"></i>
        <span slot="title">统计报表</span>
      </el-menu-item>
      <el-menu-item v-if="userPrower == '1'" index="/sonAccount">
        <i class="iconfont icon--yuangong"></i>
        <span slot="title">子账号管理</span>
      </el-menu-item>
      <!-- <el-submenu index="2">
            <template slot="title">
              <i class="el-icon-location"></i>
              <span>网点管理</span>
            </template>
            <el-menu-item index="2-1">新增网点</el-menu-item>
            <el-menu-item index="2-2">网点列表</el-menu-item>
      </el-submenu>
      <el-submenu index="2">
        <template slot="title">
          <i class="el-icon-location"></i>
          <span>导航一</span>
        </template>
        <el-menu-item index="2-1">选项1</el-menu-item>
        <el-menu-item index="2-2">选项2</el-menu-item>
      </el-submenu> -->
    </el-menu>
  </div>
</template>

<script>
export default {
  name: 'HuijiliWebMenuBar',
  props: {
    collapse: {
      type: Boolean,
      default: false
    },
    active: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      userPrower: '',
      defaultActive: ''
    };
  },
  watch: {
    active(value) {
      if (value == 'userCenter' || value == 'updatePwd') {
        this.defaultActive = ''
      }
    }
  },
  mounted() {
    let userInfo = localStorage.getItem('userInfo')
    if (userInfo) {
      userInfo = JSON.parse(userInfo)
      this.userPrower = userInfo.prower
    } else {
      this.$http({
        method: 'post',
        url: 'user_exit'
      })
      this.$router.push({
        path: '/login'
      })
    }
    this.defaultActive = this.$route.path
  },

  methods: {
    handleOpen(key, keyPath) {
    },
    handleClose(key, keyPath) {
    }
  },
};
</script>

<style lang="scss" scoped>
.el-menu {
  animation: 1s;
}
.el-menu-item.is-active {
  background: #3399FF !important;
  color: #FFFFFF;
}
.iconfont {
  margin-right: 10px;
}
</style>